var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getListSessions,"data-test":"dataTable-field","item-key":"uid","sort-by":['started_at'],"sort-desc":[true],"items-per-page":10,"footer-props":{'items-per-page-options': [10, 25, 50, 100]},"server-items-length":_vm.getNumberSessions,"options":_vm.pagination,"disable-sort":true},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" check_circle ")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" check_circle ")])]}}],null,true)},[_c('span',[_vm._v("active "+_vm._s(_vm._f("lastSeen")(item.last_seen)))])])]}},{key:"item.device",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'detailsDevice', params: { id: item.device.uid } }}},[_vm._v(" "+_vm._s(item.device.name)+" ")])]}},{key:"item.username",fn:function(ref){
var item = ref.item;
return [(!item.authenticated)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.username))])]}}],null,true)},[(!item.authenticated)?_c('span',[_vm._v("Unauthorized")]):_vm._e()]):_vm._e(),(item.authenticated)?[_vm._v(" "+_vm._s(item.username)+" ")]:_vm._e()]}},{key:"item.authenticated",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.authenticated)?_c('v-icon',_vm._g({attrs:{"color":item.active ? 'success' : '',"size":""}},on),[_vm._v(" mdi-shield-check ")]):_c('v-icon',_vm._g({attrs:{"color":"error","size":""}},on),[_vm._v(" mdi-shield-alert ")])]}}],null,true)},[(item.authenticated)?_c('span',[_vm._v("User has been authenticated")]):_c('span',[_vm._v("User has not been authenticated")])])]}},{key:"item.ip_address",fn:function(ref){
var item = ref.item;
return [_c('code',[_vm._v(_vm._s(item.ip_address))])]}},{key:"item.started",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.started_at))+" ")]}},{key:"item.last_seen",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.last_seen))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{ref:'menu'+_vm.getListSessions.indexOf(item),attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g({attrs:{"color":"transparent"}},on),[_c('v-icon',_vm._g(_vm._b({staticClass:"icons",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])],1)]}}],null,true)},[_c('v-card',[_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.detailsSession(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" info ")]),_c('v-list-item-title',[_vm._v(" Details ")])],1),(item.authenticated && item.recorded)?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();_vm.showSessionPlay(_vm.getListSessions.indexOf(item))}}},[_c('SessionPlay',{attrs:{"recorded":item.authenticated && item.recorded,"uid":item.uid,"show":_vm.sessionPlayShow[_vm.getListSessions.indexOf(item)],"data-test":"sessionPlay-component"},on:{"update:show":function($event){_vm.$set(_vm.sessionPlayShow, _vm.getListSessions.indexOf(item), $event)}}})],1):_vm._e(),(item.active)?_c('v-list-item',{on:{"click":function($event){_vm.showSessionClose(_vm.getListSessions.indexOf(item))}}},[_c('SessionClose',{attrs:{"uid":item.uid,"device":item.device_uid,"show":_vm.sessionCloseShow[_vm.getListSessions.indexOf(item)],"data-test":"sessionClose-component"},on:{"update:show":function($event){_vm.$set(_vm.sessionCloseShow, _vm.getListSessions.indexOf(item), $event)},"update":_vm.refresh}})],1):_vm._e()],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }